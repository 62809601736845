.white-button {
  padding-inline: 10px;
  height: 30px;
  border-radius: 10px;
  background: var(--seasalt);
  display: flex;
  justify-content: center;
  align-items: center;
}
.wb-title {
  font-size: 16px;
  color: var(--eerieBlack);
}
