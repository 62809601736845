.line {
  height: 1px;
  width: 100%;
  background-color: #212529;
  opacity: 0.8;
}

.vertical-line {
  height: 100%;
  width: 1px;
  background-color: #212529;
  opacity: 0.8;
}
.thick-line {
  height: 5px;
  width: 100%;
  background-color: var(--platinum);
  opacity: 0.8;
}
