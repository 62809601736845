.icon-button {
  height: 40px;
  display: flex;
  gap: 10px;
  background: var(--platinum);
  align-items: center;
  border-radius: 5px;
}
.icb-icon {
  height: 26px;
  width: 26px;
  padding-left: 10px;
  opacity: 0.8;
}
.icb-title {
  font-size: 22px;
  color: var(--eerieBlack);
  opacity: 0.8;
}
