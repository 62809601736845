.image-button {
  display: flex;
  gap: 5px;
  padding-inline: 10px;
}

.ib-left-button,
.ib-left-button-selected,
.ib-image {
  height: 50px;
  width: 60px;
  background-color: var(--platinum);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  object-fit: cover;
}

.ib-left-button-selected {
  outline: #212529 1px solid;
}
.ib-image {
  opacity: .8;
}