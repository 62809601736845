.generic-header {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--platinum);
}
.gh-title {
  font-size: 22px;
  color: var(--eerieBlack);
}
.gh-icon {
  height: 26px;
  width: 26px;
  position: absolute;
  right: 10px;
}
