.gp-location {
  font-size: 16px;
  color: var(--eerieBlack);
  opacity: 0.8;
  padding: 10px;
}
.gp-confirm-title {
  color: var(--eerieBlack);
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: center;
}

.gp-confirm-bottom-block {
  position: absolute;
  bottom: 55px;
  left: 10px;
  right: 10px;
}

.gp-bottom-text {
  color: var(--eerieBlack);
  text-align: center;
  opacity: 0.8;
  padding-bottom: 10px;
}
