.group-banner {
  height: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.group-banner-image {
  object-fit: cover;
  height: 50vw;
  width: 100%;
}
.group-banner-placeholder {
  height: 30px;
  width: 30px;
}
.gp-return {
  position: absolute;
  top: 5px;
  left: 0;
  background: radial-gradient(#dee2e6, #00000000 80%);
  border-radius: 5px;
}
.gp-blur {
  background: linear-gradient(315deg, rgba(34, 38, 42, 0.75), #00000000 75%);
  height: 100%;
  width: 100%;
  position: absolute;
}
.gp-profile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  right: 10px;
  top: calc(50vw - 50px);
  background-color: #dee2e6;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  border-radius: 35px;
  z-index: 1;
}

.gp-profile-image {
  object-fit: cover;
  height: 70px;
  width: 70px;
  border-radius: 35px;
}

/* Profile Placeholder */
.gp-profile-placeholder {
  height: 30px;
  width: 30px;
}
