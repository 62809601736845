.double-button-div {
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 5px;
  right: 5px;
  gap: 10px;
}
.double-button {
  font-size: 15px;
  color: #f8f9fa;
  background-color: #212529;
  border-radius: 5px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 35px;
}
