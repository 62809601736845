.link-button {
  height: 40px;
  background-color: var(--platinum);
  padding-left: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link-button-selected {
}

.lb-title {
  font-size: 16px;
  color: var(--eerieBlack);
  opacity: 0.8;
}

.lb-subtitle {
  font-size: 14px;
  color: var(--onyx);
  opacity: 0.8;
}

.lb-arrow {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  bottom: 5px;
  rotate: 180deg;
}
