.dual-button {
  display: flex;
  gap: 5px;
  padding-inline: 10px;
}

.db-left-button,
.db-left-button-selected {
  height: 50px;
  width: 100px;
  background-color: var(--platinum);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.dblb-title {
  font-size: 16px;
  color: var(--eerieBlack);
  opacity: 0.8;
  padding-left: 5px;
}
.dblb-subtitle {
  font-size: 14px;
  color: var(--onyx);
  opacity: 0.8;
  padding-left: 5px;
}

.db-left-button-selected {
  outline: #212529 1px solid;
}
