.header-input {
  background-color: #dee2e6;
  height: 64px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 7px;
  padding-right: 15px;
}

.hi-block {
  display: flex;
}

.hi-text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
}

.hi-input {
  font-size: 20px;
  color: #212529;
  width: 100%;
}

.hi-subtitle {
  font-size: 12px;
  color: #212529;
  opacity: 0.8;
}

.hi-icon {
  height: 26px;
  width: 26px;
  opacity: 0.8;
}
