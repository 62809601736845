.sub-header {
  display: flex;
  gap: 5px;
  padding-inline: 10px;
  padding-top: 10px;
}

.sub-header-title {
  background: var(--platinum);
  height: 35px;
  width: 100%;
  border-radius: 2px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-header-button {
  background: var(--eerieBlack);
  color: var(--platinum);
  width: 200px;
  flex-shrink: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 5px;
}
