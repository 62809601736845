.popup {
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  background: var(--platinum);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-title {
  color: var(--eerieBlack);
  font-size: 18px;
  padding-top: 5px;
}

.p-text {
  color: var(--eerieBlack);
  opacity: 0.8;
  font-size: 16px;
  padding-top: 2px;
}

.p-button-list {
  display: flex;
  gap: 5px;
  width: 100%;
  padding-inline: 10px;
  padding-bottom: 5px;
  padding-top: 20px;
  box-sizing: border-box;
}

.p-button {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--eerieBlack);
  border-radius: 5px;
  color: var(--platinum);
  width: 100%;
}

.p-exit {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 15px;
  width: 15px;
  opacity: 0.5;
}
