@keyframes fadeOut {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
}

.pf-input {
  font-size: 14px;
  color: var(--eerieBlack);
  opacity: 0.8;
  padding: 10px;
}
.pf-message {
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  color: var(--eerieBlack);
  opacity: 0.8;
  animation: fadeOut 2s 1s forwards;
}
