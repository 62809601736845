.up-input {
  font-size: 18px;
  padding-left: 10px;
  padding-block: 10px;
  color: var(--eerieBlack);
  opacity: 0.8;
}
.up-input-bottom-text {
  font-size: 14px;
  text-align: center;
  color: var(--onyx);
  opacity: 0.5;
  position: absolute;
  bottom: 10px;
  left: 20px;
  right: 20px;
}
.version-seeker{
  font-size: 12px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: var(--eerieBlack);
  opacity: .8;
}