.home-banner {
  width: calc(100% - 20px);
  margin-inline: 10px;
  margin-block: 15px;
  height: 140px;
  background: var(--eerieBlack);
  border-radius: 10px;
  position: relative;
}
.hb-message-block {
  padding-top: 20px;
  padding-left: 35px;
  padding-right: 10px;
}
.hb-message {
  color: var(--seasalt);
  font-size: 16px;
}

.hb-signature {
  color: var(--seasalt);
  font-size: 12px;
  opacity: 0.8;
}

.hb-buttons {
  position: absolute;
  display: flex;
  gap: 5px;

  bottom: 18px;
  right: 15px;
}

.hb-title {
}

.hb-next-schedule {
}

.hb-bottom-text {
}

.hb-group-profile {
}

.hb-schedule-swapper {
}

.hb-arrow-block {
}

.hb-arrow {
}

.hb-small-time {
}

.hb-time {
}
