.group-header {
  background: var(--platinum);
  padding: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.gh-input-title {
  font-size: 22px;
  color: var(--eerieBlack);
}

.gh-input-subtitle {
  font-size: 14px;
  color: var(--eerieBlack);
  opacity: 0.8;
}

.gh-loading-button {
  background: var(--frenchGray);
  height: 20px;
  width: 100px;
  padding-inline: 2px;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
  position: absolute;
  right: 10px;
  bottom: 10px;
  overflow: hidden;
}

.gh-loading-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, var(--frenchGray2) 50%, transparent 100%);
  animation: wave 2s linear infinite;
}

@keyframes wave {
  0% {
    left: -250%;
  }
  100% {
    left: 100%;
  }
}


.gh-small-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
