.item-button,
.item-button-selected {
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  background-color: var(--platinum);
  border-radius: 2px;
  box-sizing: border-box;
  width: 100%;
}

.item-button-selected {
  outline: var(--eerieBlack) 1px solid;
}

.ib-title {
  font-size: 20px;
  color: var(--eerieBlack);
  opacity: 0.8;
}

.ib-subtitle {
  font-size: 14px;
  color: var(--onyx);
  opacity: 0.8;
}

.selection-circle,
.selection-circle-selected {
  top: 5px;
  right: 5px;
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: var(--frenchGray);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection-circle-selected {
  background-color: var(--eerieBlack);
}

.selection-inner-circle {
  height: 6px;
  width: 6px;
  background-color: var(--platinum);
  border-radius: 3px;
}
